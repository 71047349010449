$black: rgba(34, 17, 34, 0.9);
$full-black: rgba(34, 17, 34, 1);
$gray: rgba(33, 33, 33, 0.5);
$light-gray: rgba(196, 196, 196, 1);
$filters-gray: #eaeaea;
$gray-disabled: rgba(217, 217, 217, 1);
$brown: rgba(167, 82, 20, 0.8);
$brown-disabled: rgba(167, 82, 20, 0.3);
$brown-light: rgba(167, 82, 20, 0.55);
$white: rgba(246, 246, 246, 1);
$red: rgba(255, 0, 0, 1);
$light-red: rgba(255, 0, 0, 0.6);
$beige: rgba(185, 117, 67, 0.25);
$white-border: #D9D9D9;
$shadow-black: 4px 4px 8px 0px rgba(56, 41, 56, 0.25);
$shadow-main: 4px 0px 8px rgba(55, 40, 55, 0.25), 0px 4px 8px rgba(55, 40, 55, 0.2);
$shadow-brown: 4px 4px 6px 2px rgba(167, 82, 20, 0.25);
$shadow-brown-80: rgba(167, 82, 20, 0.8);
$shadow-brown-press: 0px 0px 10px 3px rgba(34, 17, 34, 0.25) inset;
$silver-gradient:linear-gradient(0deg, rgba(196, 196, 196, 0.8) 0%, rgba(196, 196, 196, 0) 142.47%);
$transparent-gray-gradient:linear-gradient(270deg, #C4C4C4 0%, rgba(196, 196, 196, 0) 184.41%);
$light-red: rgba(255,179,179, 0.9);
$light-blue: rgba(211,225,253, 0.9);
$light-green: rgba(205,255,207, 0.9);
$button-territory-box-shadow: rgba(85, 80, 77, 0.25);
$button-territory-background: rgba(34, 17, 34, 0.9);
$bg-primary: rgb(246, 246, 246);
$bg-secondary: rgb(196, 196, 196);


