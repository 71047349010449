@import "../../../../../../common/ui/styles/media-sizes";
@import "../../../../../../common/ui/styles/colors";


$lg-size-1023: #{$lg-size - 1px};

.ProductCardGallery {
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: 100%;

  h3 {
    margin-bottom: 6px;
  }

  &_item {
    display: flex;
    width: 100%;
    gap: 20px;


    & input {
      display: none;
    }

    &_color {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
    }

    &_images {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      gap: 20px;
      padding-bottom: 2px;

      &::-webkit-scrollbar {
        background-color: $white;
        height: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: $brown;
        background-repeat: no-repeat;
        background-size: auto auto;
      }

      &::-webkit-scrollbar-track {
        background-color: $white;
      }
    }
  }

  &_empty_text {
    text-align: center;
  }
}