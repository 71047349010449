@import '../../../../../../common/ui/styles/colors';
@import '../../../../../../common/ui/styles/media-sizes';

$lg-size-1023: #{$lg-size - 1px};

.ProductCardColors {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  &_tabs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 5px;
    width: 100%;

    @media screen and (max-width: $lg-size-1023) {
      flex-direction: row;
      max-width: 100%;
      overflow-x: auto;

      &::-webkit-scrollbar {
        background-color: $white;
        height: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: $brown;
        background-repeat: no-repeat;
        background-size: auto auto;
      }

      &::-webkit-scrollbar-track {
        background-color: $white;
      }
    }

    &::-webkit-scrollbar {
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
    }

    &_colors {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media screen and (max-width: $lg-size-1023) {
        flex-direction: row-reverse;
      }
    }
  }

  @media screen and (max-width: $lg-size-1023) {
    flex-direction: row;
  }
}
