@import "../../../../../../common/ui/styles/media-sizes";
@import "../../../../../../common/ui/styles/colors";
@import "../../../../../../common/ui/styles/font-sizes";


.category {
  width: calc(50% - 10px);
  border: 1px solid $light-gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;

  @media screen and (max-width: $md-size) {
    width: 100%;
  }
}

.title {
  padding: 0 20px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid $light-gray;

  svg {
    width: 30px;
    height: 30px;
    animation: rotateArrowToUp 0.3s forwards;
  }

  .active {
    animation: rotateArrowToDown 0.3s forwards;
    path {
      stroke: $brown;
    }
  }

  @media screen and (max-width: $xs-size) {
    svg {
      width: 16px;
    }
  }
}

.items {
  height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  transition-duration: 0.3s;

  &_active {
    transition-duration: 0.3s;
    display: flex;
    background-color: white;
    width: 100%;
    height: 425px;
    overflow-y: auto;
  }
}

@keyframes rotateArrowToDown {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}

@keyframes rotateArrowToUp {
  0% {
    transform: rotateX(-180deg);
  }
  100% {
    transform: rotateX(-360deg);
  }
}

