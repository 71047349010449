@import "../../../../common/ui/styles/colors";
@import "../../../../common/ui/styles/font-sizes";
.colorItem {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  overflow: hidden;

  & p {
    font-size: $font-size-normal-13;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  &_circle {
    border-radius: 50%;
    border: 1px solid $black;
    min-width: 15px;
  }
}