$font-size-xlarge-36: 36px;
$font-size-xlarge-32: 32px;
$font-size-xlarge-28: 28px;
$font-size-xlarge-24: 24px;
$font-size-large-20: 20px;
$font-size-xmedium-18: 18px;
$font-size-medium-16: 16px;
$font-size-normal-13: 13px;
$font-size-normal-14: 14px;
$font-size-small-12: 12px;
$font-size-xsmall-10: 10px;
$font-size-xxsmall-8: 8px;

