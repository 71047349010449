@import "../../../../common/ui/styles/media-sizes";
@import "../../../../common/ui/styles/font-sizes";
@import "../../../../common/ui/styles/colors";
@import "../../../../common/ui/styles/variables";
@import "../../../../common/ui/styles/fonts";

.cs {
  &Select {
    position: relative;
    font-family: $main-font;
    font-style: normal;
    font-weight: 400;
    font-size: $font-size-xlarge-24;
    line-height: 28px;
    width: 100%;
    margin: 0;
    background-color: $white;
    display: flex;
    flex: 1 0 auto;

    &Small {
      font-size: $font-size-normal-13;
      line-height: 15px;
    }
  }

  &Placeholder {
    cursor: pointer;
    border: 1px solid $light-gray;
    padding: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    transition: all $animation-duration;
    min-height: 100%;
    flex-grow: 1;
    width: 100%;

    &Small {
      padding: 5px !important;
    }

    &Icon {
      width: 14px;
      height: 8px;
      min-width: 14px;
      margin-right: 26px;
      transition: transform $animation-duration;

      &Small {
        margin-right: 5px;
      }

      &.active {
        transform: scaleY(-1);

        path {
          stroke: $brown;
        }
      }
    }
  }

  &Error {
    border: 1px solid $red;
    transition: all $animation-duration;
  }

  &Option {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    background-color: $white;

    &:hover {
      background-color: $filters-gray;
    }

    &Small {
      p {
        font-size: $font-size-normal-13;
      }

      label {
        padding: 14px 8px !important;
      }
    }

    &Mark {
      height: 24px;
      width: 24px;
      min-width: 24px;
      border: 1px solid $light-gray;
      background: white;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }

    & p {
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & label {
      cursor: pointer;
      overflow: hidden;
      display: flex;
      transition: $animation-duration;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 28px 16px;

      & input:checked ~ span {
        border: none;
        background: url("../../../../common/assets/images/checkbox.svg");
        transition: 1s;
      }
    }

    & input {
      height: 100%;
      outline: none;
      display: none;
    }
  }

  &Options {
    z-index: 2;
    box-shadow: $shadow-black;
    position: absolute;
    top: 100%;
    min-width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    transition: height $animation-duration;
    background-color: $white;
    display: flex;
    flex-direction: column;
    max-height: 260px;

    &::-webkit-scrollbar {
      background-color: $white;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $brown;
      background-repeat: no-repeat;
      background-size: auto auto;
    }

    &::-webkit-scrollbar-track {
      background-color: $white;
    }

  }
}
