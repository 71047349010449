.ProductImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &_hidden {
    width: 100%;
    height: 100%;
  }

  ;

  img {
    width: 100%;
    height: 100%;
  }
}