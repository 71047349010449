@import '../../../../../common/ui/styles/media-sizes';
@import '../../../../../common/ui/styles/colors';
@import '../../../../../common/ui/styles/font-sizes';


.FooterDesktop {
  height: 320px;
  display: flex;
  flex-direction: column;
  padding: 0 7%;
}

.Footer {
  border-bottom: 1.8px solid $brown;

  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 51.63px;
  padding-top: 50px;

  &_title {
    margin-right: 7vw;
  }

  &_logo {
    font-weight: 700;
    font-size: 36px;
    cursor: pointer;
    user-select: none;


    span {
      color: $black;
    }

    @media screen and (max-width: $xl-size) {
      font-size: 24px;
    }

    letter-spacing: -0.17em;
  }

  &_menu {
    display: flex;
    text-align: center;
    padding: 30px 0;
    width: 100%;
    z-index: 2;
    gap: 6%;

    a {
      color: $black;
    }

    @media screen and (max-width: $xl-size) {
      justify-content: center;
      li {
        &:nth-child(2n) {
          margin: 0 3%;
        }
      }
    }

    &_item {
      font-size: 24px;
      text-underline-offset: 5px;

      @media screen and (max-width: $xl-size) {
        font-size: 20px;
      }


      @media screen and (max-width: $xl-size) {
        font-size: 18px;
        padding: 22px 0;
      }
    }

    &_item {
      cursor: pointer;
      text-underline-offset: 5px;
      user-select: none;


      &:hover {
        text-decoration: underline 1.8px solid $brown;
      }

      &:active {
        text-decoration: underline 1.8px solid $brown;
      }

      &_active {
        text-underline-offset: 5px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-thickness: 1.8px;
        text-decoration-color: $brown !important;
        color: $black;
      }

      &_disabled {
        position: relative;
        pointer-events: none;
        color: $gray-disabled !important;
        user-select: none;


        &_status {
          position: absolute;
          top: 15px;
          left: 0px;
          color: $brown-light;
          font-size: $font-size-xsmall-10 !important;
        }
      }
    }
  }

  &_right_side {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

    &_hanger {
      width: 21.86px;
      height: 22.37px;
      cursor: pointer;
      user-select: none;

      @media screen and (max-width: $xl-size) {
        width: 25px;
      }
    }

    &_delimiter {
      width: 24px;
      height: 0;
      border-top: 1.8px solid $brown;
      transform: rotate(-90deg);
    }

    &_text {
      display: flex;
      flex-direction: column;
      align-items: flex-end;


    }

    &_phone {
      margin-bottom: 16px;
      font-size: 24px;

      a {
        color: inherit;
      }

      @media screen and (max-width: $xl-size) {
        font-size: 18px;
      }
    }

    &_email {
      margin-bottom: 21px;
      font-size: 24px;

      a {
        color: inherit;
      }

      @media screen and (max-width: $xl-size) {
        font-size: 18px;
      }
    }

    &_menu {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      a {

        &:nth-child(2) {
          margin: 0 20px;
        }
      }

    }
  }

  &_down_side {
    display: flex;
    justify-content: space-between;
    text-decoration-line: none;
    padding-top: 35px;

    &_items {
      display: flex;
      justify-content: space-between;
      text-decoration-line: none;
      margin-bottom: 40px;

    }

    @media screen and (max-width: $xl-size) {
      padding-top: 35px;
    }

  }
}
