
.ErrorLoadingImage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;


  p {
    width: 80%;
  }

  svg {
      width: 20%;
      height: auto;
  }
}