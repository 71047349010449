@import "../../../styles/colors";

.modalOverlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.35);
}

.modalDesktopSizeFs{
  inset: 0;
}

.modalDesktopSizeLg{
  inset: 5%;
}

.modalDesktopSizeMd{
  inset: 15%;
}

.modalDesktopSizeSm{
  inset: 25%;
}

.content{
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  border: 1px solid rgb(204, 204, 204);
  background: $white;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  height: fit-content;
  max-height: 95vh;
  z-index: 10000;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: $light-gray;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $gray;
    width: 8px;
    height: 70%;
  }
}
