@import "../../../../../../common/ui/styles/colors";
@import "../../../../../../common/ui/styles/font-sizes";


.SettingsList {

    ::-webkit-scrollbar {
        width: 8px;
        background-color: $light-gray;
    }

    ::-webkit-scrollbar-thumb {
        background-color: $gray;
        width: 8px;
        height: 70%;
    }

    .listArea {
        padding-right: 8px;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 250px;
        width: 100%;
        overflow-y: scroll;
    }

}
