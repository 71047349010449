@import "../../../../styles/media-sizes";

.ModalFooter{
  display: flex;
  width: 100%;
  height: 65px;
  @media screen and (max-width: 1023px) {
    height: 125px;
    gap: 15px;
  }
  justify-content: space-between;
  padding: 20px 30px 0 30px;
  gap: 30px;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
 @media screen and (max-width: $md-size) {
  gap: 10px;
  margin-top: -10px;
 }
  button{
    width: 100%;
    height: 100%;
  }
}
