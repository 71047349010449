@import "../../../../styles/font-sizes";


.ModalHeader {
  height: 10%;
  font-size: $font-size-xlarge-24;
  display: grid;
  grid-template-columns: auto 22px;


  &_modalTitle{
    display: flex;
    justify-content: center;
  }

  &_cross{
      cursor: pointer;
  }
}


