@import "../../../../../../common/ui/styles/colors";
@import "../../../../../../common/ui/styles/media-sizes";

.ProductMobileGallerySlider {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100%;
  height: 100%;

  &_expanded {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: $full-black;
  }

  &_overlay {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &_closeIcon {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 24px;
      color: white;
      cursor: pointer;
      z-index: 1;

      &:hover path {
        fill: $gray;
      }

      path {
        fill: $light-gray;
      }
    }
  }

  &_carousel {
    margin-top: 24px;
    width: 100vw;

    &_expanded {
      display: flex !important;
      flex-direction: column;
      justify-content: flex-end;
      height: 100vh;
      width: 100vw;
      margin-bottom: 10px;
      gap: calc(85vh - (90vw / 3 * 4) - (100vw / 3 / 3 * 4));

      @media screen and (min-width: $md-size) {
        gap: calc(95vh - (90vw / 3 * 4) - (100vw / 6 / 6 * 4));
      }
    }

    &_slide {
      display: flex;
      justify-content: center;
      align-self: center;
      padding: 0 calc(10vw / 2);
      align-items: center;
      width: 100%;
      height: calc(90vw / 3 * 4);

      @media screen and (min-width: $md-size) {
        height: calc(75vw / 3 * 4);
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &_dots {
      display: flex !important;
      position: static !important;
      margin-top: 10px !important;
      margin-right: 10px !important;
      padding-bottom: 6px !important;
      max-width: 100vw;
      overflow-x: auto;
      gap: 5px;

      &::-webkit-scrollbar {
        height: 7px;
        background-color: $white;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: $brown;
        background-repeat: no-repeat;
        background-size: auto auto;
      }

      &::-webkit-scrollbar-track {
        background-color: $white;
      }


      & li {
        width: calc(100vw / 3) !important;
        height: calc((100vw / 3) / 3 * 4) !important;
        aspect-ratio: 3/4;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        margin-right: 5px !important;
        margin-left: 5px !important;

        @media screen and (min-width: $md-size) {
          width: calc(100vw / 6) !important;
          height: calc((100vw / 6) / 3 * 4) !important;
        }
      }

      & li[class='slick-active'] {
        border-bottom: 1px solid $brown;

        div {
          padding-bottom: 1px;
        }
      }
    }

    &_dot {
      width: calc(100vw / 3) !important;
      aspect-ratio: 3/4;

      @media screen and (min-width: $md-size) {
        width: calc(100vw / 6) !important;
      }

      & img {
        object-fit: contain;
      }

    }
  }
}