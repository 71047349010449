@import "../../../../common/ui/styles/colors";

.Loader {
  border: 16px solid $white;
  border-top: 16px solid $brown;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  &_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 999;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: $light-gray;
    overflow: hidden;
    opacity: 1;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}