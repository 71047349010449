@import "../../../../common/ui/styles/media-sizes";

$lg-size-1023: #{$lg-size - 1px};

.EmptyBanner {
  display: flex;
  justify-content: flex-end;
  height: calc(100vh - 100px);
  margin: 20px 0px 20px 10%;

  @media screen and (max-width: $lg-size-1023) {
    margin: 20px;
    height: auto;
  }
}