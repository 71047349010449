@import "../../../styles/variables";

.Switcher {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color $animation-duration ease;
  position: relative;

  input {
    display: none;
  }

  .indicator {
    display: block;
    border-radius: 100%;
    position: absolute;
    transition: left $animation-duration ease;
  }
}
