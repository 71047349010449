@import "../../../../../common/ui/styles/media-sizes";
@import "../../../../../common/ui/styles/colors";
@import "../../../../../common/ui/styles/font-sizes";

.active {
  border: 1px solid $brown;

  p {
    color: $black;
  }

  .img * {
    fill: $brown;
  }
}

.navbar {
  position: sticky;
  width: 224px;
  border-right: 1px solid $brown;
  display: flex;
  flex-flow: column;
  gap: 3em;
  align-items: center;
  padding-left: 1.2em;
  padding-right: 1.2em;

  .navbarList {
    width: 15em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 176px;
    height: 100px;
    border-bottom: 1px solid $gray;

    .logoTitle {
      letter-spacing: -0.17em;
      font-family: 'Roboto';
      font-size: $font-size-xlarge-36;
      font-weight: 700;
    }
  }

  a {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $gray;
    width: 9em;
    height: 8em;
    padding: 16px 28px;

    p {
      font-size: $font-size-xlarge-24;
      font-weight: 600;
      font-family: 'Roboto';
    }

    .register {
      position: absolute;
      color: $white;
      width: 24px;
      height: 24px;
      margin: auto;
      text-align: center;
      font-size: $font-size-large-20;
      background-color: $brown;
      border: solid 1px transparent;
      border-radius: 50%;
      top: 22px;
      left: 88px;
      padding-top: 2px;
    }
  }
}
