@import '../../../styles/colors';
.ButtonDefault{
    font-weight: 400;
    font-size: 24px;
    height: 56px;
    padding: 0 30px 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: $black 1px solid;
    background-color: inherit;
    box-shadow: $shadow-black;
    &:hover {
        box-shadow: none;
      }
    &_active{
      color: $gray;
      border: $gray 1px solid;
      cursor: default;
    }
}
