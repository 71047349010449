@import '../../../../../common/ui/styles/media-sizes';
@import '../../../../../common/ui/styles/colors';
@import '../../../../../common/ui/styles/font-sizes';


.FooterMobile {
  display: none;
  width: 100%;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1.8px solid $brown;

  @media screen and (max-width: $lg-size) {
    display: flex;
    flex-direction: column;
  }


  &_title {
    @media screen and (max-width: $lg-size) {
      padding-bottom: 25px;
      border-bottom: 1.8px solid $brown;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &_logo {
    font-weight: 700;
    font-size: 24px;
    cursor: pointer;
    letter-spacing: -0.17em;
  }

  &_menu {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 30px 0;
    gap: 10px;

    a {
      color: $black;
    }

    &_item {
      user-select: none;
      cursor: pointer;
      text-underline-offset: 5px;

      &:hover {
        text-decoration: underline 1.8px solid $brown;
      }

      &:active {
        text-decoration: underline 1.8px solid $brown;
      }

      &_active {
        text-underline-offset: 5px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-thickness: 1.8px;
        text-decoration-color: $brown !important;
        color: $black;
      }

      &_disabled {
        position: relative;
        pointer-events: none;
        color: $gray-disabled !important;
        user-select: none;


        &_status {
          position: absolute;
          top: 15px;
          left: 27px;
          color: $brown-light;
          font-size: $font-size-xsmall-10 !important;
        }
      }

    }
  }

  &_right_side {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 30px;
    align-items: center;
    border-top: 1.8px solid $brown;

    &_hanger {
      width: 21.86px;
      height: 22.37px;
      cursor: pointer;

      &:nth-child(2) {
        margin: 0 20px;
      }
    }

    &_delimiter {
      width: 24px;
      height: 0;
      border-top: 1.8px solid $brown;
      transform: rotate(-90deg);
    }

    &_text {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &_phone {
      margin-bottom: 16px;
      font-size: 20px;

      a {
        color: inherit;
      }
    }

    &_email {
      margin-bottom: 21px;
      font-size: 20px;

      a {
        color: inherit;
      }
    }

    &_menu {
      a {

        &:nth-child(2) {
          margin: 0 20px;
        }
      }
    }
  }


  &_wrapper {
    display: none;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 30px;
    padding: 30px 0 37.49px;

    a, a:visited {
      color: inherit;
      display: block;
    }

    @media screen and (max-width: $lg-size) {
      display: flex;
      font-size: 18px;
    }
  }

  &_menu {
    &_item {
      @media screen and (max-width: $lg-size) {
        font-size: 20px;
      }

    }
  }
}

