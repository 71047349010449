@import '../../../styles/colors';
@import '../../../styles/media-sizes';

.toaster_container{
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 480px) {
    width: 100vw;
  }
}

.toast_error{
  width: 288px;
  min-height: 94px;
  border-radius: 0;
  background: $light-red;
  color: $black;
  box-shadow: $shadow-black;

  &::before {
    content: url("../../../../../assets/images/errorIcon.svg");
    position:relative;
    z-index:10;
  }

  @media screen and (max-width: 480px) {
    width: 90vw;
    margin-bottom: 10px;
  }
}

.toaster_notification{
  width: 288px;
  height: 94px;
  border-radius: 0;
  background: $light-blue;
  color: $black;
  box-shadow: $shadow-black;

  &::before {
    content: url("../../../../../assets/images/notificationIcon.svg");
    position:relative;
    z-index:10;
  }

  @media screen and (max-width: 480px) {
    width: 90vw;
    margin-bottom: 10px;
  }
}

.toast_success{
  width: 288px;
  height: 94px;
  border-radius: 0;
  background: $light-green;
  color: $black;
  box-shadow: $shadow-black;

  &::before {
    content: url("../../../../../assets/images/successIcon.svg");
    position:relative;
    z-index:10;
  }

  @media screen and (max-width: 480px) {
    width: 90vw;
    margin-bottom: 10px;
  }
}

.toaster_body{
  font-family: 'Roboto', serif;
  font-size: 16px;

  div{
    padding-left: 7px;
  }
}