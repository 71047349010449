@import "../../../../../../common/ui/styles/font-sizes";
@import "../../../../../../common/ui/styles/colors";
@import "../../../../../../common/ui/styles/media-sizes";

.ProductsList{
  margin: 50px 0px 20px;
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, 30%);
  column-gap: 5%;
  row-gap: 30px;

  @media screen and (max-width: $xl-size) {
    grid-template-columns: repeat(2, 48%);
    column-gap: 4%;
  }

  @media screen and (max-width: $md-size) {
    grid-template-columns: 1fr;
    column-gap: unset;
  }

  #slider {
    position: fixed;
    bottom: 75px;
    right: 49px;
    cursor: pointer;

    @media all and (max-width: $lg-size) {
      bottom: 50px;
    }

    @media all and (max-width: $md-size) {
      bottom: 24px;
      right: 24px;
    }
  }

  .bottomBar {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 10%;
    width: 100%;
    background: linear-gradient(270deg, #C4C4C4 0%, rgba(196, 196, 196, 0) 184.41%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;

    @media all and (max-width: $lg-size) {
      height: 150px;
    }

    @media all and (max-width: $md-size) {
      height: 100px;
    }
  }

  #trash {
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;

    p {
      font-size: $font-size-xlarge-36;
      color: $brown;
    }
  }

  .observingBlock {
    width: 20px;
    height: 20px;
    display: none;

    &.active {
      display: block;
    }
  }

  &Link {
    display: flex;
  }
}
