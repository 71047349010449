@import "../../../../../common/ui/styles/colors";

.ZoomableImage {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100%;
  height: 100%;

  &_expanded {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;

    img {
      width: 90%;
      height: 90%;
      object-fit: contain;
    }
  }

  &_overlay {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &_closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: white;
    cursor: pointer;

    &:hover path{
      fill: $gray;
    }

    path {
      fill: $light-gray;
    }
  }

    &_swipeLeft {
      position: absolute;
      top: center;
      left: 0px;
      font-size: 24px;
      color: white;
      cursor: pointer;
      width: 60px;
      height: 60px;
  
      &:hover path {
        fill: $gray;
      }
  
      path {
        fill: $light-gray;
      }
    }
        &_swipeRight {
          position: absolute;
          top: center;
          right: 0px;
          font-size: 24px;
          color: white;
          cursor: pointer;
          width: 60px;
          height: 60px;
    
          &:hover path {
            fill: $gray;
          }
    
          path {
            fill: $light-gray;
          }
        }
}
