@import "../../../../../common/ui/styles/media-sizes";
@import "../../../../../common/ui/styles/colors";
@import "../../../../../common/ui/styles/font-sizes";

.active p {
  text-decoration: underline;
}

.adminMobileWrapper {
  position: relative;


  .mobileHeader {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $gray;
    padding: 0 28px 0 28px;

    .logoMobile {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: -0.17em;
    }

    .buttonBurgerMobile {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .mobileMenu {
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    overflow-y: auto;
    width: 100%;
    height: 100vh;
    max-height: calc(100vh - 64px);
    background-color: $white;
@media screen and (max-height: 20em) {
    justify-content: flex-start;
  }

    .linkMobileWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      a {
        position: relative;
        text-align: center;
        color: $gray;
        width: 350px;
        height: 50px;


        p {
          display: inline;
          font-size: $font-size-xlarge-24;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }

        .register {
          position: absolute;
          color: $white;
          width: 18px;
          height: 18px;
          padding-top: 2px;
          text-align: center;
          font-size: $font-size-normal-13;
          background-color: $brown;
          border: solid 1px transparent;
          border-radius: 50%;
          top: 0px;
          left: 210px;

        }

      }
    }

    .exit {
      text-align: center;
      color: $gray;

      p {
        display: inline;
        font-size: $font-size-xlarge-24;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
    }
  }
}