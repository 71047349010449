@import "../../../../../../common/ui/styles/colors";

.Table {
  display: flex;

  &_color {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid $brown;

    &_wrapper {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

  }

  &_item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $brown-disabled;

    &_available {
      color: $brown;

      &:hover {
        color: $white;
        background-color: $brown;
        cursor: pointer;
      }
    }

    &_current {
      background-color: $brown;
      color: $white
    }
  }

  &_current_column {
    background-color: $light-gray;
  }

  table {
    display: table;

    tr {
      display: table-cell;
    }

    tr td {
      width: 38px;
      height: 38px;
      display: block;
    }
  }
}