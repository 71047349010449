@import "../../../../../common/ui/styles/colors";
@import "../../../../../common/ui/styles/media-sizes";


.ProductMobile {
  display: flex;
  flex-direction: column;

  &_return {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    width: 100vw;
    gap: 20px;
    background: $light-gray;

    h4 {
      color: $black;
    }
  }

  &_description {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 12px 28px;

    &_item {
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        color: $gray;
        font-size: 16px;
      }
    }

    &_label {
      min-width: 132px;
      font-size: 18px;
    }

    &_size {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      color: $brown;
      text-decoration: underline;
      padding-left: 10px;
    }

    &_tableColors {
      max-width: 90vw;
      overflow-x: auto;

      &::-webkit-scrollbar {
        background-color: $white;
        height: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: $brown;
        background-repeat: no-repeat;
        background-size: auto auto;
      }

      &::-webkit-scrollbar-track {
        background-color: $white;
      }
    }
  }

}