.dialog {
  &Wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 22px;
  }

  &Input {
    height: 45px;
    width: 100%;
    border: 1px solid rgba(34, 17, 34, 0.9);
    padding: 8px 16px;
    font-size: 24px;
    margin-bottom: 16px;
  }

  &Description {
    height: 147px;
    width: 100%;
    border: 1px solid rgba(34, 17, 34, 0.9);
    padding: 8px 16px;
    font-size: 24px;
    resize: none;
  }

  &Error {
    border: 1px solid red;
    color: red;

    &Message {
      display: block;
      color: red;
      margin-bottom: 12px;
    }
  }

  &Img {
    width: auto;
    max-width: 90px;
    background: #C4C4C4;
    margin-top: 18px;
    aspect-ratio: 3 / 4;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &ImgLoader {
    height: 69px;
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;

    & label {
      cursor: pointer;
      display: flex;
      justify-content: center;
    }

    & input {
      opacity: 0;
      display: none;
    }
  }
}

.buttonLink {
  background: transparent;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-decoration-line: underline;
  color: rgba(167, 82, 20, 0.8);
  filter: drop-shadow(4px 4px 8px rgba(56, 41, 56, 0.25));
}

.displayNone {
  display: none;
}

.visible {
  visibility: hidden;
  display: flex;
  width: 100%;
  height: 0;
}
