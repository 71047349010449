@import '../../../../../common/ui/styles/media-sizes';
@import '../../../../../common/ui/styles/colors';
@import '../../../../../common/ui/styles/font-sizes';



.Header_wrapper_fixed {
  position: fixed;
  z-index: 1;
  background-color: $white;
  width: 100%;
}

.Header {
  border-bottom: 2px solid $white-border;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 28px 0 28px;

  &_logo {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.17em;
    color: $black;
  }

  &_right {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;

    div:last-child {
        margin-right: 25px;

      path {
        fill: $gray-disabled;
      }
    }

    &_icon {
      display: flex;
      align-items: center;

      svg {
        width: 24px;
        height: auto;
      }
    }
  }
}

.Menu {
  background-color: $white;
  position: fixed;
  width: 100%;
  z-index: 1;
  padding-top: 50px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 70px);
  overflow-y: auto;

  &_nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
    gap: 30px;

    a {
      color: $black;
      width: 100%;
      text-align: center;

      &.active {
        text-underline-offset: 5px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-thickness: 1.8px;
        text-decoration-color: $brown;
      }
    }

    &_item {
      font-size: 24px;
      text-underline-offset: 5px;
      user-select: none;


      &:hover {
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-thickness: 1.8px;
        text-decoration-color: $brown;
      }

      &:active {
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-thickness: 1.8px;
        text-decoration-color: $brown;
      }

      &_disabled {
        position: relative;
        pointer-events: none;
        color: $gray-disabled !important;
        user-select: none;


        &_status {
          position: absolute;
          top: 15px;
          left: 27px;
          color: $brown-light;
          font-size: $font-size-xsmall-10 !important;
        }
      }
    }

    &_number {
      font-size: 20px;

      a {
        color: inherit;
      }
    }
  }
}
