@import "../../../../common/ui/styles/media-sizes";
@import "../../../../common/ui/styles/colors";
@import "../../../../common/ui/styles/font-sizes";

.CatalogSettings{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 25px 25px 100px;
    .Way{
        color: $light-gray;
        margin-bottom: 15px;
    }

    h2{
        margin: 0 auto 70px;
        padding: 0 20px 20px;
        border-bottom: 1px solid $brown;
        text-align: center;
    }

    @media screen and (max-width: $md-size){
        h2{
            margin: 0 auto 60px;
        }
    }

    @media screen and (max-width: $sm-size) {
        .Way{
            display: none;
        }
        h2{
            font-size: 30px;
            padding: 0;
            width: 100%;
            margin: 0 0 20px;
            min-height: 40px;
        }
        h3{
            font-size: 22px;
        }
    }
    @media screen and (max-width: $xs-size) {
        .Way{
            display: none;
        }

        h2{
            font-size: 24px;
            padding: 0;
            width: 100%;
            text-align: center;
        }

        h3{
            font-size: 16px;
        }
    }
}

.categories{
    height: fit-content;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 32px;
}

.category{
    width: calc(50% - 10px);
    border: 1px solid $light-gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    @media screen and (max-width: $md-size) {
        width: 100%;
    }
}

.items{
    height: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    transition-duration: 0.3s;
    &_active{
        transition-duration: 0.3s;
        display: flex;
        background-color: white;
        width: 100%;
        height: 300px;
        overflow-y: auto;
    }
}

.buttons{
    width: 100%;
    button{
        width: 264px;
    }
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    @media screen and (max-width: $sm-size) {
        flex-direction: column-reverse;

        button{
            width: 100%;
            text-align: center;
        }
    }
}

