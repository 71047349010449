@import '../../../../common/ui/styles/colors';
@import '../../../../common/ui/styles/font-sizes';


.breadcrumbs {
  margin: 25px 0 0 25px;
  color: $light-gray;
  display: flex;
  flex-wrap: wrap;

  span {
    margin-left: 6px;
    margin-right: 6px;
  }

  a, p {
    font-size: $font-size-normal-14;
    color: $light-gray;
    white-space: nowrap;
  }

  a:hover {
    color: $gray;
    text-decoration: underline $brown;
  }
}
