@import "../../../../../common/ui/styles/colors";
@import "../../../../../common/ui/styles/media-sizes";


.Product_wrapper {
  display: flex;
  flex-direction: column;
  background-color: $white;
}

.Breadcrumbs_wrapper {
  margin-left: 35px;
}

.Product {
  display: flex;
  margin: 24px 60px 20px 60px;
  gap: 121px;


  &_carousel {
    display: flex;
    gap: 30px;
    width: auto;
    max-height: 80vh;

    &_list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 8px;
      max-height: 80vh;
      overflow-y: scroll;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        background-color: $white;
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $brown;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-size: auto auto;
      }

      &::-webkit-scrollbar-track {
        background-color: $white;
      }

      &_item {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 102px;
        max-width: 102px;
        min-height: calc(102px / 3 * 4);
        max-height: calc(102px / 3 * 4);
        aspect-ratio: 3 / 4;
        margin-right: 5px;

        img {
          object-fit: contain;
        }

        &_current {
          padding-bottom: 4px;
          border-bottom: 2px solid $brown;
          border-radius: 2px;
        }
      }
    }

    &_selected_item {
      position: relative;
      top: 0;
      width: 30vw;
      height: calc(30vw / 3 * 4);

      img {
        object-fit: contain;
      }

      &_up {
        animation: move-up 0.5s ease forwards;
      }

      &_down {
        animation: move-down 0.5s ease forwards;
      }

      @keyframes move-up {
        0% {
          top: 100px;
        }
        100% {
          top: 0;
        }
      }

      @keyframes move-down {
        0% {
          top: -100px;
        }
        100% {
          top: 0;
        }
      }
    }
  }

  &_description {
    display: flex;
    flex-direction: column;
    width: calc((100vw - 121px - 120px) / 2);
    gap: 24px;

    &_item {
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        color: $gray
      }
    }

    &_label {
      min-width: 170px;
    }

    &_container {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 48px;
    }

    &_table {
      display: flex;

      &_labels {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      &_label {
        display: flex;
        height: 28px;
        align-items: flex-end;
      }

      &_colors {
        display: flex;
        overflow-x: auto;

        &::-webkit-scrollbar {
          background-color: $white;
          height: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background-color: $brown;
          background-repeat: no-repeat;
          background-size: auto auto;
        }

        &::-webkit-scrollbar-track {
          background-color: $white;
        }
      }

      &_container {
        display: flex;
      }
    }

    &_size {
      display: flex;
      flex-direction: column;

      h4 {
        margin-left: 10px;
        text-decoration: underline;
        color: $brown;

        &:hover {
          cursor: pointer;
        }
      }


    }
  }

}