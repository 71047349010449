@import '../../../../../../../common/ui/styles/colors';
@import '../../../../../../../common/ui/styles/media-sizes';
@import '../../../../../../../common/ui/styles/font-sizes';


.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: calc(100vh - 10vh - 13vh);

  .Form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 400px;
    height: 538px;
    border: 1px solid $black;
    box-shadow: 0px 7px 20px $light-gray;
    background-color: $white;

    @media screen and (max-height: 800px) {
      height: 67vh;
    }

    input {
      background-color: inherit;
      width: 100%;
      height: 30px;
      margin-left: 14px;
      font-size: $font-size-xlarge-24;
    }

    &_entry_label {
      text-align: center;
      width: 150px;
      border-bottom: 1px solid $brown;
    }

    &_input_fields {
      height: 140px;

      &_img_input {
        margin-left: 32px;
      }

      &_input {
        display: flex;
        align-items: center;
        color: $black;
        width: 370px;
        height: 50px;

        &_valid {
          border-bottom: 1px solid $black;
        }

        &_invalid {
          border-bottom: 1px solid red;
        }

        img {
          width: 25px;
          height: 25px;
        }

        &_password {
          margin-top: 40px;
        }
      }

      &_error {
        position: absolute;
        padding-top: 10px;
        color: red;
        height: 30px;
      }
    }
  }
}

