@import '../../styles/media-sizes';
@import '../../styles/font-sizes';

.Typography {
  font-family: 'Roboto', serif;
  font-style: normal;
  font-size: $font-size-xlarge-24;
  line-height: 28px;
  @media screen and (max-width: $xl-size) {
    font-size: $font-size-xlarge-24;
  }

  @media screen and (max-width: $xl-size) {
    font-size: $font-size-xmedium-18;
  }
}
