@import '../../../../../../../common/ui/styles/colors';
@import '../../../../../../../common/ui/styles/media-sizes';
@import '../../../../../../../common/ui/styles/font-sizes';

.Form {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  &_entry_label {
    text-align: center;
    border-bottom: 1px solid $brown;
    width: 150px;
  }

  input {
    background-color: inherit;
    width: 100%;
    height: 30px;
    margin-left: 14px;
    font-size: $font-size-large-20;
  }

  &_input_fields {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 95%;
    height: 33vh;

    &_input {
      display: flex;
      align-items: center;
      color: $black;
      padding-left: 12px;
      padding-bottom: 9px;

      &_valid {
        border-bottom: 1px solid $black;
      }

      &_invalid {
        border-bottom: 1px solid red;
      }
    }

    &_img_input {
      width: 23px;
      height: 23px;
    }

    &_error {
      position: absolute;
      padding-top: 10px;
      height: 30px;
      color: red;

      @media screen and (orientation: landscape) {
        position: static;
      }
    }
  }

  &_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 175px;
    height: 56px;
    color: $white;
    font-size: $font-size-xlarge-24;
    box-shadow: 4px 4px 6px $beige;
    background-color: $brown;
  }

  &_btn:disabled {
    background-color: gray;
    cursor: default;
  }
}
