@import '../../../styles/colors';
.ButtonAccent{
    font-weight: 400;
    font-size: 24px;
    height: 56px;
    padding: 0 30px 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    border: none;
    box-shadow: $shadow-brown;
    background-color: $brown;
    &:hover {
        box-shadow: none;
    }
    &_active{
        box-shadow: $shadow-brown-press;
        cursor: default;
    }
    &:disabled{
        background-color: gray;
        box-shadow: none;
        cursor: default;
    }
}
