.ImageSkeleton {
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 50%);
  animation: background 2s infinite alternate;
}

@keyframes background {
  100% {
    background-position: 300px 0;
  }
}