@import '../../../../../common/ui/styles/media-sizes';
@import '../../../../../common/ui/styles/colors';
@import '../../../../../common/ui/styles/font-sizes';

.Header {
  display: flex;
  height: 100px;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid $light-gray;

  &_logo_wrapper {
    display: flex;
    flex: 3;
    justify-content: flex-end;
    font-weight: 700;
    font-size: 36px;
    cursor: pointer;
    @media screen and (max-width: $xl-size) {
      font-size: 32px;
    }
    letter-spacing: -0.17em;

    span {
      color: $black;

    }

    &_logo {
      margin-right: 25%;
    }
  }

  &_menu {
    flex: 7;
    display: flex;
    width: 100%;
    gap: 5%;
    justify-content: center;
    align-items: center;

    a {
      color: $gray;

      &.active {
        text-underline-offset: 5px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-thickness: 1.8px;
        text-decoration-color: $brown;
        color: $black;
      }
    }

    &_item {
      cursor: pointer;
      text-underline-offset: 5px;
      user-select: none;

      &:hover {
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-thickness: 1.8px;
        text-decoration-color: $brown;
      }

      &:active {
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-thickness: 1.8px;
        text-decoration-color: $brown;
      }

      &_disabled {
        position: relative;
        text-align: center;
        pointer-events: none;
        color: $gray-disabled !important;
        user-select: none;

        &_status {
          position: absolute;
          top: 15px;
          left: 0px;
          color: $brown-light;
          font-size: $font-size-xsmall-10 !important;
        }

      }

    }
  }

  &_right_side {
    width: 100%;
    flex: 3;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    &_hanger {
      display: flex;
      align-items: center;

      @media screen and (max-width: $xl-size) {
        width: 25px;
      }

      svg {
        width: 30px;
        height: auto;

        path {
          fill: $gray-disabled;
        }
      }

    }

    &_number {
      color: inherit;
    }

    &_delimiter {
      width: 24px;
      height: 0;
      border-top: 1.8px solid $brown;
      transform: rotate(-90deg);
      margin: 0 4%;
    }
  }
}
