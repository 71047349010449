@import "../../../../../../../../common/ui/styles/colors";
@import "../../../../../../../../common/ui/styles/font-sizes";
@import "../../../../../../../../common/ui/styles/media-sizes";

.ProductCard {
  padding: 18px;
  background-color: #FFFFFF;
  border: 1px $gray solid;
  box-shadow: $shadow-main;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 337px;
  min-height: 595px;

  &.active {
    outline: 2px solid $brown;
    border: 1px solid $brown;

    .description {
      h3 {
        color: $brown;
      }
    }
  }

  &_mainImage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    min-height: 400px;
    max-height: 400px;
    aspect-ratio: 3 / 4;


    &.hovered {
      img {
        opacity: 0.7;
      }
    }

    img {
      user-select: none;
      object-fit: contain;
      align-self: center;
      aspect-ratio: 3 / 4;
    }
  }

  .checkbox {
    position: absolute;
    top: 25px;
    left: 25px;
    width: 45px;
    height: 45px;
  }

  .update {
    position: absolute;
    bottom: 195px;
    right: 25px;
    width: 45px;
    height: 45px;
  }

  .description {
    display: grid;
    grid-template-rows: 1fr 4fr;
    grid-gap: 10%;
    height: 100%;
    margin-top: 15px;

    &_name {
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        text-align: center;
        font-weight: 400;
        font-size: $font-size-xlarge-24;
        color: $black;
      }
    }

    .options {
      display: flex;
      flex-direction: column;
      gap: 2px;

      .optionItem {
        display: flex;
        align-items: center;
        flex-basis: 50px;


        p {
          width: 80px;
        }

        .items {
          display: flex;
          flex-wrap: wrap;
          padding-right: 5px;
          justify-content: flex-end;
          column-gap: 5%;
          row-gap: 6px;
          width: 100%;
          margin-left: 10px;
        }

        &.sizes {
          .items {
            color: $gray;
            flex-wrap: wrap;

            span {
              border: $gray 1px solid;
              padding: 2px 5px;
              font-size: $font-size-xmedium-18;
            }
          }
        }

        &.colors {
          .items {
            span {
              display: block;
              width: 20px;
              height: 20px;
              border-radius: 100%;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $xl-size) {

    .description {
      .options {
        .optionItem {
          p {
            font-size: $font-size-medium-16;
          }

          .items {
            column-gap: 5%;
          }

          &.sizes {
            .items {
              span {
                font-size: $font-size-normal-14
              }
            }
          }
        }
      }
    }
  }
}
