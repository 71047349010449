@import "../../../styles/colors";
@import "../../../styles/media-sizes";
.modalOverlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.35);
  overflow-y: auto;
  @media screen and (max-width: $md-size) {
     overflow-y: auto;
    }
}

.modalMobileSizeFs {
  width: 100%;
  inset: 0;
}

.modalMobileSizeLg {
  width: 100%;
  inset: 5% 0;
}

.modalMobileSizeMd {
  width: 100%;
  inset: 15% 0;
}

.modalMobileSizeSm {
  width: 100%;
  inset: 20% 0;
}

.content {
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: $white;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  height: fit-content;
  z-index: 10000;

}