@import '../../../../../../common/ui/styles/colors';
@import '../../../../../../common/ui/styles/variables';

@mixin icon-color {
  path {
    transition: all ease-in-out $animation-duration;
  }

  &:hover {
    & path {
      stroke: $black;
      transition: all ease-in-out $animation-duration;
    }
  }
}

.pci {
  &_content {
    display: flex;
    max-width: 400px;
    height: 276px;
    min-width: 209px;
    border: 1px solid $bg-secondary;
    background-color: $bg-primary;
    align-items: center;
  }

  &_iconAdd {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    @include icon-color;
    transition: all ease-in-out $animation-duration;
    cursor: pointer;

    svg {
      max-height: 122px;
    }

  }

  &_iconDelete {
    @include icon-color;

    cursor: pointer;
    height: 22px;
    position: absolute;
    right: 14px;
    transition: all ease-in-out $animation-duration;
    top: 14px;
    width: 22px;
    z-index: 10;

    & path {
      stroke: $bg-secondary;
    }
  }

  &_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 276px;
    width: 207px;
    position: relative;
  }

  &_image {
    max-height: 100%;
    max-width: 100%;
  }

  &_loader {
    animation: background 500s infinite alternate;
    background: linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 50%);
    height: 100%;
    width: 100%;
  }

  @keyframes background {
    100% {
      background-position: 100000px 0;
    }
  }
}
