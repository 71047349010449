.ProductCardSkeleton{
  width: 330px;
  min-height: 500px;
  display: flex;
  flex-direction: column;


  &_image {
    height: 75%;
    width: 100%;
    background: linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 50%);
    animation: background 500s infinite alternate;
  }

  @keyframes background {
    100% {
      background-position: 100000px 0;
    }
  }

  &_footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 25%;
    align-items: center;
    padding-top: 15px;
    gap: 10px
  }

  &_name {
    height: 20px;
    width: 50%;
    background: linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 50%);
    animation: background 500s infinite alternate;
  }

  &_infoRow{
    display: grid;
    grid-template-columns: 1fr 200px;
    height: 25px;
    width: 100%;
  }

  &_firstRow{
    margin-top: 15px;
  }

  &_property{
    height: 100%;
    background: linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 50%);
    animation: background 500s infinite alternate;
  }

  &_propertyValues{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  &_propertyValue{
    height: 100%;
    width: 35px;
    background: linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 50%);
    animation: background 500s infinite alternate;
    border-radius: 22.5px;
  }
}
